<script>
	export let asr

	const links = [
		{
			title: 'Balances',
			state: 'app.finance.balances',
		},
		{
			title: 'Format',
			state: 'app.finance.formatLedger',
		},
	]

</script>

<div class="container">
	<h2>Finance</h2>
	<ul class="nav nav-pills mb-3">
		{#each links as { title, state }}
			<li class="nav-item">
				<a
					class="nav-link px-1 px-md-2 px-lg-3"
					href={asr.makePath(state, undefined, { inherit: false })}
					class:active={asr.getActiveState().name === state}
				>
					{title}
				</a>
			</li>
		{/each}
	</ul>

	<uiView />
</div>
