<script>
	import { parseTransactions } from './parse-transactions.js'

	let transactions = ''
	let total = ''

	let ledger = ''
	let error
	$: {
		try {
			ledger = transactions && parseTransactions(transactions, total)
		} catch (e) {
			error = e.message
		}
	}

	const copyToClipboard = () => {
		navigator.clipboard.writeText(ledger)
			.then(() => {
				console.log('Content copied to clipboard')
			})
			.catch(e => {
				error = e.message
			})
	}
</script>

<div class="card">
	<div class="card-body">
		<h5 class="card-title">Format Entries to Ledger</h5>
		<p>
			Copy paste <em>completed</em> transactions from the bank into here
			and they'll be formatted into what you should put in the ledger.
		</p>
		<div class="mb-3">
			<label for="transactions" class="form-label">Paste in the copied transactions:</label>
			<textarea class="form-control" id="transactions" rows="5" bind:value={transactions}></textarea>
		</div>
		<div class="mb-3">
			<label for="total" class="form-label">Paste in the current balance (with or without $):</label>
			<input type="text" class="form-control" id="total" bind:value={total}>
			<p class="form-text">
				Use the "ending collected balance as of" field, and make sure
				the last transaction is not past that ending date.
			</p>
		</div>
		<hr>
		{#if error}
			<div class="alert alert-danger">{error}</div>
		{/if}
		<p>
			Here is what you should
			<a href="#copy-to-clipboard" on:click|preventDefault={copyToClipboard}>copy (click to copy)</a>
			and then paste into the
			<a href="https://github.com/tobiaslabs/financial-ledger">Financial Ledger</a>:
		</p>
		<pre>{ledger}</pre>
	</div>
</div>
