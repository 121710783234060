import template from './+page.svelte'
import { get } from '@/service/api.js'

const unpack = params => {
	let out = { redirectTo: { name: 'app' } }
	if (params.goto) {
		try {
			const original = JSON.parse(params.goto)
			if (original.name) out.redirectTo = original
		} catch (ignore) {
			//
		}
	}
	return out
}

export default {
	template,
	resolve: async (_, params) => {
		const me = await get('/api/me').catch(_ => _)
		if (me?.body?.data) return Promise.reject(unpack(params))
		return {
			params,
		}
	},
}
