import { get } from '@/service/api.js'

let promises = {}

export const getCurrentUser = async () => {
	if (transition) {
		if (!promises.currentUser) promises.currentUser = get('/api/me').then(res => res.body.data)
		return promises.currentUser
	}
	return get('/api/me').then(res => res.body.data)
}

let transition
export const startPageTransition = () => {
	transition = true
	promises = {}
}
export const endPageTransition = () => {
	transition = false
}
