import template from './+page.svelte'
import { getCurrentUser } from '@/lib/load-cache.js'

export default {
	template,
	defaultChild: 'dashboard',
	resolve: async () => {
		const [
			currentUser,
		] = await Promise.all([
			getCurrentUser(),
		])
		return {
			currentUser,
		}
	},
}
