const CHECKING = 'Assets:US:WF:Checking'
const DISTRIBUTION = 'Equity:Shareholder:Distribution'
const EXPENSE = {
	BankingFees: 'Expenses:Banking:Fees',
	ClientSoftware: 'Expenses:Client:Software',
	Donations501c3: 'Expenses:Donations:501c3',
	FoodClientMeals: 'Expenses:Food:ClientMeals',
	FoodOther: 'Expenses:Food:Other',
	LegalFees: 'Expenses:Legal:Fees',
	OfficeUtilities: 'Expenses:Office:Utilities',
	OfficeSmallToolsEquipment: 'Expenses:Office:SmallToolsEquipment',
	OfficeSoftware: 'Expenses:Office:Software',
	OfficeSupplies: 'Expenses:Office:Supplies',
	VehicleFuel: 'Expenses:Vehicle:Fuel',
	Uncategorized: 'Expenses:Uncategorized',
}

const storeTag = line => `store_${/\s#(\d+)\s/.exec(line)[1]}`
const simpleStoreNumberTag = line => `store_${/\w+ (\d+)/i.exec(line)[1]}`
const amazonAfterAsteriskId = line => `id_${/\*(\S+)/.exec(line)[1]}`

const companies = {
	'A HILL OF BEANS CO': {
		example: 'A HILL OF BEANS CO OMAHA NE S383042585533769',
		parse: () => ({
			payee: 'A Hill of Beans',
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'ALG': {
		example: 'ALG 2I2301437515 HTTPSWWW.ALGO CA S383007526086979',
		parse: line => ({
			payee: 'Algolia',
			tags: [ `id_${/alg\s+(\S+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'AMAZON WEB SERVICE': {
		example: 'Amazon web service aws.amazon.co WA S583033428622111',
		parse: () => ({
			payee: 'Amazon Web Service',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'AMAZON.COM*': {
		example: 'AMAZON.COM*258864L AMZN.COM/BILL WA S582044612530981',
		parse: line => ({
			payee: 'Amazon.com',
			tags: [ amazonAfterAsteriskId(line) ],
			from: CHECKING,
			to: EXPENSE.OfficeSupplies,
		}),
	},

	'AMZN MKTP US': {
		example: 'AMZN Mktp US*I92R5 Amzn.com/bill WA S301106325112526',
		parse: line => ({
			payee: 'Amazon Marketplace US',
			tags: [ amazonAfterAsteriskId(line) ],
			from: CHECKING,
			to: EXPENSE.OfficeSupplies,
		}),
	},

	'APPLE.COM/US': {
		example: 'APPLE.COM/US 800-676-2775 CA S381111681493319',
		parse: () => ({
			payee: 'Apple.com',
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'AUTONOMOUS INC': {
		example: 'Autonomous Inc. 190-93169286 NY S461109825527880',
		parse: () => ({
			payee: 'Autonomous Inc.',
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'B2B PRIME*': {
		example: 'B2B Prime*1N9W32K6 Amzn.com/bill WA S382079773700023',
		parse: line => ({
			payee: 'Amazon Prime (B2B)',
			tags: [ amazonAfterAsteriskId(line) ],
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'BAKERS #': {
		example: 'BAKERS #0320 OMAHA NE S583042755213079',
		parse: line => ({
			payee: 'Bakers',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'BEST BUY': {
		example: 'BEST BUY 0000 OMAHA NE S583042603729669',
		parse: line => ({
			payee: 'Best Buy',
			tags: [ `store_${/best buy (\S+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'BOOTSTRAP THEMES': {
		example: 'BOOTSTRAP THEMES HTTPSTHEMES.G TX S461118808105166',
		parse: () => ({
			payee: 'Bootstrap Themes',
			tags: [ 'client_MrCarShipper' ],
			from: CHECKING,
			to: EXPENSE.ClientSoftware,
		}),
	},

	'BURGER KING': {
		example: 'BURGER KING #897 OMAHA NE S462355013078796',
		parse: line => ({
			payee: 'Burger King',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'CARRD HTTPS': {
		example: 'CARRD HTTPSCARRD.CO TN S462238120192152',
		parse: () => ({
			payee: 'Carrd',
			tags: [ 'project_TakeMeOffHeroku' ],
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'CASEYS': {
		example: 'CASEYS #6118 OMAHA NE S582030569307244',
		parse: line => ({
			payee: 'Caseys',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.VehicleFuel,
		}),
	},

	'CLOUDFLARE HTTPS': {
		example: 'CLOUDFLARE HTTPSWWW.CLOU CA S463046505377569',
		parse: () => ({
			payee: 'Cloudflare',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'DAIRY QUEEN': {
		example: 'DAIRY QUEEN #14768 RALSTON NE S582327032350487',
		parse: line => ({
			payee: 'Dairy Queen',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'DIGITALOCEAN.COM': {
		example: 'DIGITALOCEAN.COM DIGITALOCEAN. NY S383032203907399',
		parse: () => ({
			payee: 'Digital Ocean',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'DON & MILLIES': {
		example: 'DON & MILLIES OMAHA NE S382326857519789',
		parse: () => ({
			payee: 'Don & Millies',
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'DREAMHOST DH-FEE': {
		example: 'DREAMHOST DH-FEE.COM CA S582356335812822',
		parse: () => ({
			payee: 'Dreamhost',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'GITHUB HTTPS': {
		example: 'GITHUB HTTPSGITHUB.C CA S583042699474363',
		parse: () => ({
			payee: 'GitHub',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'HOBBY-LOBBY': {
		example: 'HOBBY-LOBBY #0051 OMAHA NE S303042634234668',
		parse: line => ({
			payee: 'Hobby-Lobby',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'HOVER': {
		example: 'HOVER 866-731-6556 MS S463038400462461',
		parse: () => ({
			payee: 'Hover',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'HY-VEE DOTCOM': {
		example: 'HY-VEE DOTCOM WDM 515-267-2800 IA S463006645981142',
		parse: () => ({
			payee: 'Hy-Vee',
			tags: [ 'store_online', 'client_MrCarShipper', 'purpose_gift' ],
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'HY-VEE OMAHA': {
		example: 'HY-VEE OMAHA 1535 OMAHA NE S582280015990425',
		parse: line => ({
			payee: 'Hy-Vee',
			tags: [ `store_${/hy.?vee \w+ (\d+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'INTUIT *': {
		example: 'INTUIT *QBooks Onl CL.INTUIT.COM CA S302353427128305',
		parse: () => ({
			payee: 'Intuit QuickBooks',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'JETBRAINS AMERICAS': {
		example: 'JetBrains Americas 165-05772345 CA S303020828476749',
		parse: () => ({
			payee: 'JetBrains Americas',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'JACKSON STREET BOO': {
		example: 'JACKSON STREET BOO OMAHA NE S462355716012422',
		parse: () => ({
			payee: 'Jackson Street Bookstores',
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'JAKES CIGARS': {
		example: 'JAKES CIGARS & SPI OMAHA NE S582342689372926',
		parse: () => ({
			payee: 'Jakes Cigars & Spirits',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'LIBRARY PUB': {
		example: 'LIBRARY PUB OMAHA NE S382018060928775',
		parse: () => ({
			payee: 'Library Pub',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'MCDONALD\'S': {
		example: 'MCDONALD\'S F4721 OMAHA NE S582355673298733',
		parse: line => ({
			payee: 'McDonald\'s',
			tags: [ `store_${/mcdonald.?s ([\w\d]+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'MONGODBCLOUD MR CA': {
		example: 'MONGODBCLOUD MR CA MONGODB.COM CA S383035440353916',
		parse: () => ({
			payee: 'MongoDB Cloud',
			tags: [ 'client_MrCarShipper', 'purpose_saas' ],
			from: CHECKING,
			to: EXPENSE.ClientSoftware,
		}),
	},

	'MONGODBCLOUD TOBIA': {
		example: 'MONGODBCLOUD TOBIA MONGODB.COM CA S383035440353916',
		parse: () => ({
			payee: 'MongoDB Cloud',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'NI SOS EDOC': {
		example: 'NI SOS EDOC FILNG 800-7478177 NE S461198781637512',
		parse: () => ({
			payee: 'Nebraska Secretary of State',
			from: CHECKING,
			to: EXPENSE.LegalFees,
		}),
	},

	'NI SOS LLC': {
		example: 'NI SOS LLC LLP 800-7478177 NE S583009562102787',
		parse: () => ({
			payee: 'Nebraska Secretary of State',
			from: CHECKING,
			to: EXPENSE.LegalFees,
		}),
	},

	'OFFICEMAX/DEPOT': {
		example: 'OFFICEMAX/DEPOT 64 OMAHA NE S302043826441253',
		parse: line => ({
			payee: 'Office Max',
			tags: [ `store_${/[\w/]+ (\d+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.OfficeSupplies,
		}),
	},

	'PADDLE.NET* ALFRED': {
		example: 'PADDLE.NET* ALFRED PADDLE.COM NY S462105604748838',
		parse: () => ({
			payee: 'Alfred',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'PAYPAL *GADGETPICK': {
		example: 'PAYPAL *GADGETPICK 402-935-7733 FL S303035752931297',
		parse: () => ({
			payee: 'GadgetPick',
			tags: [ 'phone' ],
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'PI* W E STEVENS PC': {
		example: 'PI* W E STEVENS PC WESTEVENS.COM NE S583031253770562',
		parse: () => ({
			payee: 'W.E. Stevens, P.C.',
			from: CHECKING,
			to: EXPENSE.LegalFees,
		}),
	},

	'PILOT': {
		example: 'PILOT_00686 GRETNA NE S582234034176973',
		parse: line => ({
			payee: 'Pilot',
			tags: [ `store_${/pilot_(\S+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.VehicleFuel,
		}),
	},

	'PLAYITAGAINSP': {
		example: 'PLAYITAGAINSP #116 OMAHA NE S302094628729763',
		parse: line => ({
			payee: 'Play it Again Sports',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'PLUS.EXCALIDRAW.CO': {
		example: 'PLUS.EXCALIDRAW.CO BRNO CZE S303028780052142',
		parse: () => ({
			payee: 'Excalidraw',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'POTBELLY': {
		example: 'POTBELLY #519 OMAHA NE S462126580345696',
		parse: line => ({
			payee: 'Potbelly Sandwiches',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'QT': {
		example: 'QT 579 OMAHA NE S462047555086823',
		parse: line => ({
			payee: 'QT',
			tags: [ simpleStoreNumberTag(line) ],
			from: CHECKING,
			to: EXPENSE.VehicleFuel,
		}),
	},

	'ROAST': {
		example: 'ROAST OMAHA NE S462047485483856',
		parse: () => ({
			payee: 'Roast Coffee',
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'SCOOTERS COFFEE': {
		example: 'SCOOTERS COFFEE - OMAHA NE S462032682190876',
		parse: () => ({
			payee: 'Scooters Coffee',
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'SONIC DRIVE IN': {
		example: 'SONIC DRIVE IN #45 402-898-9292 NE S383043017831711',
		parse: line => ({
			payee: 'Sonic Drive In',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'SP * AWAIRSTORE': {
		example: 'SP * AWAIRSTORE HTTPSAWAIRSTO CA S381184036766826',
		parse: () => ({
			payee: 'Awair',
			from: CHECKING,
			to: EXPENSE.OfficeSmallToolsEquipment,
		}),
	},

	'SUBLIME HQ PTY LTD': {
		example: 'SUBLIME HQ PTY LTD DOUBLE BAY AUS S381232120119581',
		parse: () => ({
			payee: 'Sublime HQ',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'SUBWAY': {
		example: 'Subway 42732 Ralston NE S381221639874845',
		parse: line => ({
			payee: 'Subway',
			tags: [ simpleStoreNumberTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'TACO BELL': {
		example: 'TACO BELL 35280 OMAHA NE S583042643643537',
		parse: line => ({
			payee: 'Taco Bell',
			tags: [ `store_${/taco bell (\S+)/i.exec(line)[1]}` ],
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'TARGET': {
		example: 'TARGET 0002 OMAHA NE S581211092814970',
		parse: line => ({
			payee: 'Target',
			tags: [ simpleStoreNumberTag(line) ],
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'THRIFT AMERICA': {
		example: 'THRIFT AMERICA-LA LA VISTA NE S382120591327475',
		parse: () => ({
			payee: 'Thrift America',
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'TRELLO.COM* ATLASS': {
		example: 'TRELLO.COM* ATLASS ATLASSIAN.COM NY S303020610553100',
		parse: () => ({
			payee: 'Atlassian',
			tags: [ 'client_MrCarShipper', 'purpose_saas' ],
			from: CHECKING,
			to: EXPENSE.ClientSoftware,
		}),
	},

	'TST* BURRITO ENVY': {
		example: 'TST* Burrito Envy Omaha NE S582342679514275',
		parse: () => ({
			payee: 'Burrito Envy',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TST* CEDAR': {
		example: 'TST* CEDAR OMAHA NE S462090738409383',
		parse: () => ({
			payee: 'Cedar (Bar)',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TST* HERBE SAINTE': {
		example: 'TST* HERBE SAINTE OMAHA NE S582070094234477',
		parse: () => ({
			payee: 'Herbe Sainte',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TST* PUBLIC HOUSE': {
		example: 'TST* PUBLIC HOUSE OMAHA NE S582216848925170',
		parse: () => ({
			payee: 'Public House',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TST* STORIES COF': {
		example: 'TST* STORIES COFFE OMAHA NE S582090688534107',
		parse: () => ({
			payee: 'Stories Coffee',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TST* YOSHITOMO': {
		example: 'TST* Yoshitomo Omaha NE S462224021143440',
		parse: () => ({
			payee: 'Yoshimoto',
			from: CHECKING,
			to: EXPENSE.FoodClientMeals,
		}),
	},

	'TWITTER ONLINE ADS': {
		example: 'Twitter Online Ads 415-2229670 CA S302245800289823',
		parse: () => ({
			payee: 'Twitter',
			from: CHECKING,
			to: EXPENSE.Uncategorized,
		}),
	},

	'UNITY': {
		example: 'UNITY COPENHAGEN DNK S582113146906112',
		parse: () => ({
			payee: 'Unity',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

	'US BANK': {
		example: 'US BANK NA CHK XXXXXX3171 T. DAVIS REF #FP0CDGDVDB SALARY',
		parse: line => {
			if (!line.includes('SALARY')) throw new Error()
			return {
				payee: 'US Bank',
				from: CHECKING,
				to: DISTRIBUTION,
			}
		},
	},

	'WALGREENS': {
		example: 'WALGREENS #4974 OMAHA NE S381169098374502',
		parse: line => ({
			payee: 'Walgreens',
			tags: [ storeTag(line) ],
			from: CHECKING,
			to: EXPENSE.OfficeSupplies,
		}),
	},

	'WENDYS': {
		example: 'WENDYS 3439 OMAHA NE S302313667736751',
		parse: line => ({
			payee: 'Wendys',
			tags: [ simpleStoreNumberTag(line) ],
			from: CHECKING,
			to: EXPENSE.FoodOther,
		}),
	},

	'WIKIMEDIA': {
		example: 'Wikimedia 877 600 877-6009454 CA S462328068791617',
		parse: () => ({
			payee: 'Wikimedia',
			from: CHECKING,
			to: EXPENSE.Donations501c3,
		}),
	},

	'ZOOM.US': {
		example: 'ZOOM.US 888-799-96 WWW.ZOOM.US CA S463029723002166',
		parse: () => ({
			payee: 'Zoom.us',
			from: CHECKING,
			to: EXPENSE.OfficeSoftware,
		}),
	},

}

const AUTHORIZED_ON = /(?:RECURRING PAYMENT|PURCHASE) AUTHORIZED ON (\d\d).(\d\d)\s*/

const requiredProps = [
	'date',
	'payee',
	'from',
	'to',
	'amount',
]

const parse = string => {
	let [ date, text, deposit, amount ] = string.split('\t')

	let [ month, day, year ] = date.split('/')
	date = `20${year}-${month}-${day}`

	let posted = date
	const authDate = AUTHORIZED_ON.exec(text)
	if (authDate) posted = `20${year}-${authDate[1]}-${authDate[2]}`

	deposit = deposit.replace(/^\$/, '')
	amount = amount.replace(/^\$/, '')

	text = text.replace(/ CARD 1351$/, '')
	text = text.replace(AUTHORIZED_ON, '')
	text = text.replace('RECURRING TRANSFER TO ', '')

	let additional
	for (const key in companies) {
		if (text.toUpperCase().startsWith(key)) {
			try {
				additional = companies[key].parse(text)
			} catch (ignore) {
				//
			}
			if (!additional || !additional.payee) throw new Error('Could not parse company.\nExpected example: ' + companies[key].example + '\nActual text:      ' + text)
		}
	}
	if (!additional) throw new Error('Could not locate company for transaction:\n' + text)

	const out = { date, posted, payee: '', narration: text, amount, ...additional }
	if (!requiredProps.every(prop => out[prop])) {
		throw new Error('Missing fields (' + requiredProps.filter(p => !out[p]).join(',') + ') for this line:\n' + string + '\n' + JSON.stringify(out, undefined, 4))
	}
	return out
}

const DOLLAR_LINE_WIDTH = '  Assets:US:WF:Checking                             -35.00 USD'.length

const ledger = ({ date, payee, narration, tags, from, to, amount }) => {
	const lines = []
	const tagString = tags?.length
		? ' ' + tags.map(t => '#' + t).join(' ')
		: ''
	lines.push(`${date} * "${payee}" "${narration}"${tagString}`)

	lines.push(`  ${from.padEnd(DOLLAR_LINE_WIDTH - 2 - `-${amount} USD`.length, ' ')} -${amount} USD`)
	lines.push(`  ${to.padEnd(DOLLAR_LINE_WIDTH - 2 - `${amount} USD`.length, ' ')} ${amount} USD`)
	lines.push('')

	return lines.join('\n')
}

export const parseTransactions = (lines, balance) => {
	const entries = lines
		.trim()
		.split('\n')
		.map(l => parse(l))
		.sort((a, b) => a.date.localeCompare(b.date))
		.map(l => ledger(l))
		.join('\n')

	const now = new Date().toISOString()

	const balancePrefix = `${now.split('T')[0]} balance Assets:US:WF:Checking`
	const balanceSuffix = `${balance.replace('$', '')} USD`

	return `
; ========== Imported: ${now} ==========

${entries}
${balancePrefix.padEnd(DOLLAR_LINE_WIDTH - balanceSuffix.length + 1, ' ')}${balanceSuffix}
`
}
