import { get as localstorageGet } from '@/service/localstorage.js'

const api = (method, url, options) => fetch(
	url,
	Object.assign(
		{
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic ${localstorageGet('session') || ''}`,
			},
		},
		options?.body
			? { body: JSON.stringify(options?.body) }
			: {},
	),
)
	.then(async response => ({
		status: response.status,
		body: await response.json(),
	}))
	.then(response => {
		if (response.status >= 400) throw response
		return response
	})

export const get = api.bind(null, 'GET')
export const del = api.bind(null, 'DELETE')
export const post = api.bind(null, 'POST')
export const put = api.bind(null, 'PUT')
