<script>
	import { onMount } from 'svelte'
	import { get } from '@/service/api.js'
	import { parseFinancialLedger } from '@/lib/parse-financial-ledger.js'

	let ledger
	onMount(() => {
		get('/api/financial_ledger').then(res => { ledger = res.body.data })
	})

	$: ledgerLines = ledger && parseFinancialLedger(ledger) || []
</script>

<div class="container">
	<h2>Dashboard</h2>

	<div class="card">
		<div class="card-body py-0 pt-1">
			<table class="table table-sm">
				<tbody>
				{#each ledgerLines as { accounts, amount }}
					<tr>
						{#each accounts as acc}
							<td>{acc}</td>
						{/each}
						<td
							class="text-end"
							colspan={5 - accounts.length}
						>{amount || ''}</td>
					</tr>
				{/each}
				</tbody>
			</table>
		</div>
	</div>

</div>
