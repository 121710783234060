import { writable } from 'svelte/store'

import createStateRouter from 'abstract-state-router'
import SvelteRenderer from 'svelte-state-renderer'

export const router = createStateRouter(
	SvelteRenderer({
		props: {},
	}),
	document.querySelector('body'),
)

export const previousRoutes = writable()
