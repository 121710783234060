<script>
	import { get } from '@/service/api.js'
	import { set } from '@/service/localstorage.js'

	export let asr

	const links = [
		{
			icon: '💰',
			title: 'Finance',
			state: 'app.finance',
		},
	]

	const logout = () => {
		get('/api/logout')
			.then(() => {
				set('session', '')
				asr.go('login')
			})
	}
</script>

<style>
	.main {
		min-height: 100vh;
	}
	/*.active {*/
		/*text-decoration: underline;*/
	/*}*/
</style>

<div class="main container-xs bg-body-secondary pb-2">
	<nav class="navbar navbar-expand-xl mb-3 bg-body">
		<div class="container-fluid">
			<a class="navbar-brand me-1 me-md-2 me-lg-3" href="/">
				<img src="/favicon.svg" alt="🥼" width="30" height="24">
				Tobias Labs
			</a>
			<ul class="nav me-auto">
				{#each links as { icon, title, state }}
					<li class="nav-item">
						<a
							class="nav-link px-1 px-md-2 px-lg-3"
							href={asr.makePath(state, undefined, { inherit: false })}
							class:active={asr.getActiveState().name === state}
						>
							{icon || ''}
							{title}
						</a>
					</li>
				{/each}
			</ul>
			<div class="d-flex">
				<a href="/api/logout" class="nav-link" on:click|preventDefault={logout}>
					Log Out
				</a>
			</div>
		</div>
	</nav>
	<uiView />
</div>
