import { router, previousRoutes } from '@/service/router.js'
import { startPageTransition, endPageTransition } from '@/lib/load-cache.js'

import login from '@/route/login/+page.js'
import app from '@/route/app/+page.js'
import app_dashboard from '@/route/app/dashboard/+page.js'
import app_finance from '@/route/app/finance/+page.js'
import app_finance_formatLedger from '@/route/app/finance/formatLedger/+page.js'
import app_finance_balances from '@/route/app/finance/balances/+page.js'

const routes = [
	[ 'login', '/login', login ],
	[ 'app', '/app', app ],
	[ 'app.dashboard', '/dashboard', app_dashboard ],
	[ 'app.finance', '/finance', app_finance ],
	[ 'app.finance.formatLedger', '/formatLedger', app_finance_formatLedger ],
	[ 'app.finance.balances', '/balances', app_finance_balances ],
]

for (const [ name, route, state ] of routes) {
	state.name = name
	state.route = route
	router.addState(state)
}

const stateChangeHistory = []

router.on('routeNotFound', (...args) => { console.error('routeNotFound', ...args) })
router.on('stateError', error => { console.error('stateError', error) })
router.on('stateChangeError', error => {
	console.error('stateChangeError', error)
	if (error.status === 403 || error.status === 401) {
		const params = {}
		const previousState = stateChangeHistory[stateChangeHistory.length - 1]
		if (previousState && !(previousState.state.name === 'app.dashboard' && !Object.keys(previousState.parameters).length)) {
			params.goto = JSON.stringify({
				name: previousState.state.name,
				params: previousState.parameters,
			})
		}
		router.go('login', params)
	} else console.error('main:stateChangeError', error)
})

router.on('stateChangeEnd', () => {
	endPageTransition()
	previousRoutes.set(stateChangeHistory)
})

router.on('stateChangeStart', (state, parameters) => {
	startPageTransition()
	stateChangeHistory.push({ state, parameters })
	window.scrollTo(0, 0)
})

console.log('Starting app...')
router.evaluateCurrentRoute('login')
